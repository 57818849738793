<template>
  <div class="container-header">
    <div class="menu-login-header">
      <div
        id="menu-login"
        @mouseover="toggleMyAccountMenu(true)"
        @focus="toggleMyAccountMenu(true)"
        @active="toggleMyAccountMenu(true)"
      >
        <button
          v-if="!isAuthenticated"
          id="btn_popover_login"
          class="btn_popover_login"
          data-placement="bottom"
          @mouseleave="isMenuOpen = false"
        >
          <div class="login-header" :class="{ login: isMenuOpen }">
            <div class="lh-item">
              <small>Entre ou cadastre-se </small>
              <span>
                <b>Minha conta</b>
                <i
                  style="padding: 0 5px"
                  class="fa fa-angle-down"
                  aria-hidden="true"
                ></i>
              </span>
            </div>
          </div>
          <i
            class="mdi mdi-account-outline icon-login-header"
            :class="{ login: isMenuOpen }"
            aria-hidden="true"
          ></i>
        </button>

        <button
          v-if="isAuthenticated"
          id="btn_popover_login"
          class="btn_popover_login"
          data-placement="bottom"
          @mouseleave="isMenuOpen = false"
        >
          <div class="login-header" :class="{ login: isMenuOpen }">
            <!-- Olá,
          <br />Minha Conta
          <i class="fa fa-angle-down" aria-hidden="true"></i> -->

            <div class="lh-item">
              <small
                style="
                  text-transform: capitalize;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
                >{{ username }}
              </small>
              <span>
                <b>Minha conta</b>
                <i
                  style="padding: 0 5px"
                  class="fa fa-angle-down"
                  aria-hidden="true"
                ></i>
              </span>
            </div>
          </div>
          <i
            class="mdi mdi-account-outline icon-login-header"
            :class="{ login: isMenuOpen }"
            aria-hidden="true"
          ></i>
        </button>

        <div
          v-show="isMenuOpen"
          id="popover_content_login"
          @mouseleave="toggleMyAccountMenu(false)"
          @click="toggleMyAccountMenu(false)"
        >
          <form v-if="!isAuthenticated" action role="form">
            <!-- <div class="form-group" v-if="false">
            <label for>Usuário</label>
            <input type="text" v-model="usuario" class="form-control" placeholder="Usuário" />
          </div>
          <div class="form-group" v-if="false">
            <label for>Senha</label>
            <input type="password" v-model="senha" class="form-control" placeholder="Senha" />
          </div>-->

            <button type="button" class="button-lvcliente">
              <router-link :to="{ name: 'login' }" tag="a">
                <span class="account-shortcut-icon">
                  <i class="mdi mdi-login-variant"></i>
                </span>

                Entrar
              </router-link>
            </button>

            <button type="button" class="button-lvcliente btn-test">
              <router-link :to="{ name: 'cadastro' }" tag="a">
                <span class="account-shortcut-icon">
                  <i class="mdi mdi-account-plus-outline"></i>
                </span>

                Cadastrar
              </router-link>
            </button>
          </form>
          <form v-if="isAuthenticated" action role="form">
            <router-link :to="{ name: 'MinhaConta' }">
              <span class="account-shortcut-icon">
                <i class="mdi mdi-account-outline"></i>
              </span>
              Minha Conta
            </router-link>
            <!-- <hr class="divisor-logado" /> -->
            <router-link :to="{ name: 'MinhaContaPedidos' }">
              <span class="account-shortcut-icon">
                <i class="mdi mdi-file-chart-outline"></i>
              </span>
              Pedidos
            </router-link>

            <router-link :to="{ name: 'MinhaContaCarrinhoSalvos' }">
              <span class="account-shortcut-icon">
                <i class="mdi mdi-cart-outline"></i>
              </span>
              Carrinhos salvos
            </router-link>

            <router-link :to="{ name: 'MinhaContaCadastro' }">
              <span class="account-shortcut-icon">
                <i class="mdi mdi mdi-pencil-outline"></i>
              </span>
              Cadastro
            </router-link>

            <router-link :to="{ name: 'MinhaContaTitulos' }">
              <span class="account-shortcut-icon">
                <i class="mdi mdi-file-document-multiple-outline"></i>
              </span>
              Títulos
            </router-link>

            <span class="span-divisor"></span>
            <a class target="_self" @click="logout">
              <span class="account-shortcut-icon">
                <i class="mdi mdi-logout-variant"></i>
              </span>
              Sair
            </a>
          </form>
        </div>

        <over-screen
          v-if="isMenuOpen"
          @click.native="toggleMyAccountMenu(false)"
        ></over-screen>
      </div>
      <router-link
        v-if="isAuthenticated && showOfertasExclusivas"
        tag="button"
        class="btn_popover_login"
        data-placement="bottom"
        to="/campanhas"
      >
        <div class="login-header">
          <div class="lh-item">
            <small>Ofertas</small>
            <span>
              <b>Exclusivas</b>
            </span>
          </div>
        </div>
        <!-- <i class="fa fa-percent icon-login-header" aria-hidden="true"></i> -->
        <img
          class="icon-login-header"
          draggable="false"
          src="@/assets/img/percent.svg"
          alt=""
        />
      </router-link>
    </div>
    <!-- <div class="cart">
      <i class="fa fa-shopping-basket fa-2x icon-cart"></i>
    </div>-->
    <ModalCadastro
      v-if="showModalCadastro"
      :show-modal="showModalCadastro"
      :forgot-password="true"
      :token="token"
      @close="closeModalCadastro"
    ></ModalCadastro>
  </div>
</template>

<script>
import ModalCadastro from "@/components/root/modal/ModalCadastro";
import { mascaraCpfCnpj } from "@/app/global";

import OverScreen from "@/components/root/overscreen/OverScreen";
import Popover from "@/components/popover/Popover";

import bus from "@/utils/events/bus";

export default {
  name: "BtnLogin",
  components: {
    ModalCadastro,
    OverScreen,
    Popover,
  },
  data() {
    return {
      usuario: "",
      senha: "",
      showOfertasExclusivas:
        this.$store.getters.siteConfig.app_store_show_ofertas_exclusivas ||
        false,
      showModalCadastro: false,
      token: "",
      loginUrl: process.env.VUE_APP_CLIENTE,
      isMenuOpen: false,
    };
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.getIsAuthenticated;
    },
    redirectPath() {
      return `redirect=${window.location.origin}${this.$route.fullPath} `;
    },
    username() {
      // create const variable user from getUsuarioInfo in vuex
      const user = this.$store.getters.getUsuarioInfo;

      if (!user) return "Olá";
      if (isNaN(user.usuario)) {
        return user.usuario;
      }

      return mascaraCpfCnpj(user.usuario);
    },
  },
  created() {},
  mounted() {
    // if (!Account.isAuthenticated()) {
    //   this.isAuthenticated = false;
    // } else {
    //   this.isAuthenticated = true;
    // }
  },
  methods: {
    logout() {
      Account.logout();
    },
    openModalCadastro() {
      this.showModalCadastro = true;
    },
    closeModalCadastro() {
      this.showModalCadastro = false;
    },
    toggleMyAccountMenu(event) {
      if (window.screen.width <= 760) {
        bus.$emit("toggleHeaderMenuResponsible");
        this.isMenuOpen = false;
        return;
      }

      this.isMenuOpen = event;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";

.green-icon {
  color: var(--brand-primary, $brand-primary);
}

.container-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0px 4px;
  align-self: center;
  height: 60px;
  flex: 1 1 auto;
  align-items: center;

  .cart {
    color: #fff;
    flex: 0 1 auto;

    .icon-cart {
      margin-top: 31px;
      font-size: 2em;
    }
  }
}

.login-header {
  margin: 0px;
  // color: #fff;
  font-size: 13px;
  text-align: left;
  white-space: nowrap;
  padding: 10px 0px;
  max-width: 115px;

  &.login {
    z-index: 100;
  }

  .lh-item {
    display: flex;
    flex-direction: column;
  }
}

.icon-login-header {
  // color: #fff;
  margin-right: 5px;
  align-self: center;
  font-size: 26px;

  &.login {
    z-index: 100;
  }
}

.menu-login-header {
  padding-right: 0px;
  padding-left: 0px;
  margin-right: 5px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
}

.button-lvcliente {
  background: transparent;
  border: none;
  margin: auto;
  width: 100%;

  display: block;
  flex-direction: row-reverse;
}

.menu-login-header .btn_popover_login {
  background: transparent;
  border: none;
  float: right;
  transition: 0.3s ease-in;
  display: flex;
  flex-direction: row-reverse;
  padding-right: 5px;
  border-radius: 30px;

  &:last-child {
    padding-right: 0;
  }
}

.menu-login-header button:focus,
.menu-login-header button:active,
.btn-search:focus,
.btn-search:active {
  box-shadow: 0 0 0 0 !important;
  outline: 0 !important;
}

@media (max-width: 991px) {
  /*   .container-header {
    float: left;
    position: relative;
    right: 0;
    justify-content: flex-end;

    p {
      font-size: 15px;
      line-height: 15px;
    }

    .icon-login-header {
      font-size: 30px !important;
    }
  }

  .icon-login-header {
    font-size: 32px;
  } */
}

@media (max-width: 525px) {
  .icon-cart {
    display: none;
  }

  .container-header {
    float: right;
    position: relative;
    right: 0;
    height: 100%;

    .menu-login-header {
      right: 0;
      margin-top: 0;
      margin-right: 5px;
      padding: 0px 5px;

      .btn_popover_login {
        .login-header {
          display: none;
        }
        .icon-login-header {
          font-size: 27px !important;
          margin-top: 0;
        }
      }
    }
  }
}

#popover_content_login {
  position: absolute;
  background: #fff;
  top: 40px;
  right: -20px;
  z-index: 100;
  display: block;
  border-radius: 6px;
  min-width: 15em;
  padding: 12px;
  box-shadow: 0 0 7px -3px #a7a7a7;
  margin-top: 15px;
  // display: none;

  .btn-entrar {
    box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0.15);
  }

  .btn-sair {
    background: #fb5050;
    box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0.15);
    &:hover {
      background: #d34343;
    }
  }

  .divisor-logado {
    margin: 3px;
  }

  a {
    display: block;
    text-align: left;
    border-radius: 4px;
    cursor: pointer;
    font-size: 15px;
    margin: 0px 0px;
    padding: 5px 0px;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: $gray;

    &:hover {
      background: $gray-lighter;
      color: var(--brand-primary, $brand-primary);
    }

    .account-shortcut-icon {
      font-size: 21px;
      padding: 0 10px;
    }
  }

  form {
    // padding: 10px;
  }
}

#popover_content_login:before {
  width: 0;
  height: 0;
  content: "";
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #fff;
  top: -10px;
  position: absolute;
  right: 21.5px;
}

#menu-login {
  position: relative;
  // z-index: 999999; // todo: sem z-index, ou com valor menor, o botão não fica clicável no mobile. identificar o que está causando esse problema.

  &:hover,
  &:active,
  &:focus {
    #popover_content_login {
      display: block;
    }
  }
}

@media (max-width: 768px) {
  .menu-login-section .menu-login-header #popover_content_login {
    margin-top: 15px;
    right: 0;
    z-index: 9999;

    &:before {
      top: -10px;
      margin-right: 8px;

      right: 0%;
    }
  }
}

.span-divisor {
  position: relative;
  text-align: center;
  width: 100%;
  display: block;
  margin-bottom: 10px;
  margin-top: 5px;
}
// .span-divisor:before {
//   content: "";
//   height: 10px;
//   width: 40px;
//   top: -4px;
//   left: 50%;
//   position: absolute;
//   z-index: 200;
//   color: #666;
//   background: #fff;
//   margin-left: -20px;
//   font-size: 13px;
// }
.span-divisor:after {
  content: "";
  height: 1px;
  width: 100%;
  border-bottom: 1px solid #e5e5e5;
  top: 0px;
  left: 0;
  position: absolute;
  z-index: 1;
}

@media (max-width: 1295px) {
  .icon-login-header {
    font-size: 2.3rem !important;
  }

  .login-header {
    font-size: 1.2rem;
  }
}
</style>

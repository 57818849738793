import download from 'downloadjs'

// mime type [xls, csv], default: xls
let type;
// Json to download
let data
// fields inside the Json Object that you want to export
// if no given, all the properties in the Json are exported
let fields
// Title for the data
let title
// filename to export, deault: data.xls
let name
let meta
const delimiter = ','

export const generate = (options = {}) => {
    this.type = options.type
    this.data = options.data
    this.fields = options.fields
    this.title = options.title
    this.name = options.name
    this.meta = options.meta
    this.delimiter = options.delimiter != undefined && options.delimiter != null ? options.delimiter : this.delimiter;

    if (!this.data.length) {
        return
    }
    const json = getProcessedJson(this.data, this.fields)
    if (this.type == 'csv') {
        return exportt(jsonToCSV(json), this.name, "application/csv");
    }
    return exportt(jsonToXLS(json), this.name, "application/vnd.ms-excel");
}
/*
Use downloadjs to generate the download link
*/
export const exportt = (data, filename, mime) => {
    const blob = base64ToBlob(data, mime)
    download(blob, filename, mime)
}
/*
jsonToXLS
---------------
Transform json data into an xml document with MS Excel format, sadly
this format show a prompt when open due to a default behavior
on Microsoft office. It's recommended to use CSV format instead.
*/
export const jsonToXLS = (data) => {
    const xlsTemp = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta name=ProgId content=Excel.Sheet> <meta name=Generator content="Microsoft Excel 11"><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>${table}</table></body></html>'
    let xlsData = '<thead><tr>'

    if (this.title != null) {
        if (Array.isArray(this.title)) {
            for (let i = 0; i < this.title.length; i++) {
                xlsData += `<th colspan="${  Object.keys(data[0]).length  }">${  this.title[i]  }<th></tr><tr>`
            }
        } else {
            xlsData += `<th colspan="${  Object.keys(data[0]).length  }">${  this.title  }<th></tr><tr>`
        }
    }

    for (const key in data[0]) {
        xlsData += `<th>${  key  }</th>`
    }
    xlsData += '</tr></thead>'
    xlsData += '<tbody>'

    data.map((item, index) => {
        xlsData += '<tbody><tr>'
        for (const key in item) {
            xlsData += `<td>${  item[key]  }</td>`
        }
        xlsData += '</tr></tbody>'
    })
    return xlsTemp.replace('${table}', xlsData)
}
/*
jsonToCSV
---------------
Transform json data into an CSV file.
*/
export const jsonToCSV = (data) => {
    let csvData = ''

    if (this.title != null) {
        if (Array.isArray(this.title)) {
            for (let i = 0; i < this.title.length; i++) {
                csvData += `${this.title[i]  }\r\n`
            }
        } else {
            csvData += `${this.title  }\r\n`
        }
    }

    for (const key in data[0]) {
        csvData += key + this.delimiter
    }
    csvData = csvData.slice(0, csvData.length - 1)
    csvData += '\r\n'

    const _self = this

    data.map((item) => {
        for (const key in item) {
            let escapedCSV = `${item[key]  }` // cast Numbers to string
            if (escapedCSV.match(/[,"\n]/)) {
                escapedCSV = `"${  escapedCSV.replace(/\"/g, "\"\"")  }"`
            }
            csvData += escapedCSV + _self.delimiter
        }
        csvData = csvData.slice(0, csvData.length - 1)
        csvData += '\r\n'
    })
    return csvData
}
/*
getProcessedJson
---------------
Get only the data to export, if no fields are set return all the data
*/
export const getProcessedJson = (data, header) => {
    const keys = getKeys(data, header)
    const newData = []
    data.map((item, index) => {
        const newItem = {}
        for (const label in keys) {
            const iii = item;
            const property = keys[label]
            newItem[label] = getNestedData(property, item)
        }
        newData.push(newItem)
    })

    return newData
}
export const getKeys = (data, header) => {
    if (header) {
        return header
    }

    const keys = {}
    for (const key in data[0]) {
        keys[key] = key
    }
    return keys
}
export const callItemCallback = (field, itemValue) => {
    if (typeof field === 'object' && typeof field.callback === 'function') {
        return field.callback(itemValue);
    }

    return itemValue;
}
export const getNestedData = (key, item) => {
    const field = (typeof key === 'object') ? key.field : key;

    let valueFromNestedKey = null
    const keyNestedSplit = field.split(".")

    valueFromNestedKey = item[keyNestedSplit[0]]
    for (let j = 1; j < keyNestedSplit.length; j++) {
        valueFromNestedKey = valueFromNestedKey[keyNestedSplit[j]]
    }

    valueFromNestedKey = callItemCallback(key, valueFromNestedKey);

    return valueFromNestedKey;
}
export const base64ToBlob = (data, mime) => {
    const base64 = window.btoa(window.unescape(encodeURIComponent(data)))
    const bstr = atob(base64)
    let n = bstr.length
    const u8arr = new Uint8ClampedArray(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], {
        type: mime
    })
}
